import { images } from '../constants/images';
import { PageRoutes } from './page-routes';
import { Page } from './pages-types';
import { FormattedString } from '../i18n/useMessages';
import { Kumbh2025PageContent } from './kumbh-2025.content';

export const makarSankrantiMessages = {
  'makar-sankranti.subtitle': 'The turning of seasons.',
  'makar-sankranti.hero-image-alt': 'A bright, sunlit field of white flowers.',
  'makar-sankranti.summary':
    'One of the most auspicious days in the Hindu calendar. The turning of seasons, and beginning anew.',
  'makar-sankranti.content': `
    <p>
      This is one of the most auspicious days in the Hindu calendar, when the Sun makes
      a transition into the zodiac sign of Capricorn (Makara). It symbolizes the end of
      winter and the beginning of longer days. The day is considered especially
      favourable for offering charity to the needy to capture divine blessings, and for
      taking a dip in the holy river Ganges.
    </p>
    <paragraph>
      Makar Sankranti is also seen to be the ideal time to start things anew, and for
      reinvention of one’s life and self, similar to the turning of seasons.
    </paragraph>
  `,
};

export const makarSankrantiPage: Page = {
  title: 'camp67-navigation.kumbh-2025.makar-sankranti',
  subtitle: 'makar-sankranti.subtitle',
  summary: <FormattedString message="makar-sankranti.summary" />,
  path: PageRoutes.makarSankranti,
  heroImage: images['makar-sankranti-hero'],
  heroImageAlt: 'makar-sankranti.hero-image-alt',
  readingTime: 2,
  content: (
    <Kumbh2025PageContent
      contentKey="makar-sankranti.content"
      eventId="makar-sankranti"
    />
  ),
  linkedPages: (s) => [
    s.getPageOrThrow(PageRoutes.mauniAmavasya),
    s.getPageOrThrow(PageRoutes.basantPanchami),
  ],
};
