import { Link } from '@remix-run/react';
import { images } from '../constants/images';
import { PageRoutes } from './page-routes';

import { Page } from './pages-types';
import { FormattedString } from '../i18n/useMessages';

export const whoWeAreMessages = {
  'camp67-who-we-are.subtitle': 'The spirit behind camp67.',
  'camp67-who-we-are.hero-image-alt':
    'A sunset view of a port in Varanasi, with boats on the River Ganges.',
  'camp67-who-we-are.summary':
    'From the team behind Kumbh Sukrit comes camp67: the most affordable, serene and peaceful offering at the Kumbh 2025.',
  'camp67-who-we-are.content': `
  <p>
        In one sentence: <strong>camp67</strong> is a labour of craft, cultural tradition,
        and reverence for the tradition of Kumbh.
      </p>
      <paragraph>
        We are a diverse group of individuals with varying professional and personal
        backgrounds, all who come together and put aside our achievements and roles to
        celebrate this great adventure which millions of pilgrims from around the world
        embark upon every six years. To us, Kumbh is the gateway to change.
      </paragraph>
      <paragraph>
        camp67 is our vision for a Kumbh campsite which welcomes one and all. The
        'all' part of this is utmost important to us, for the spirit and
        underlying principle of Kumbh is "Human".
      </paragraph>
      <paragraph>
        At camp67 not only will you meet people from all over the world who are on their
        own adventure through life but be able to sit with them in our 24-hour cafe and
        discuss your stories of life. You'll meet bloggers and travellers, all who
        can tell you where the best street-food can be found in Prayag Raj. You'll
        meet our team, who, as much as they'll have uniforms on, will sit with you
        and tell you the stories of the
        <the-land-link>land of camp67</the-land-link>
        and bewilder you with its uniqueness.
      </paragraph>
      <paragraph>
        Why are we excited for you to join us? Because we're confident you'll
        love it here, and that you'll want to come back.
      </paragraph>
      <paragraph>
        Trust us,
        <camp67-kumbh-link>we've done it before</camp67-kumbh-link>: in the 2019 Kumbh Mela, as Kumbh Sukrit. 
      </paragraph>
      <paragraph>
        Kumbh Sukrit has been engaged in Kumbh for over a decade. Having hosted 10,000+ guests from over 25 countries from around the world, we are a team of professionals from across various industries, with a passion for (1) the traditions of Ancient India, and (2) high-quality service.
      </paragraph>
      <paragraph>
        Over the last decade, this has taken us on a unique journey through the incredible heritage and uniqueness of the Kumbh Mela.
        In 2019, we hosted thousands of guests from around the globe in a tent village in the famed “Sanskriti Vann”, where ancient Rishis and Yogis meditated for years and decades.
      </paragraph>
      <paragraph>
        With all of these experiences, we're so excited to bring forward camp67 and provide you with a lifetime experience of the Kumbh Mela.
      </paragraph>
      `,
  'camp67-who-we-are-summary':
    'From the team behind Kumbh Sukrit comes camp67: the most affordable, serene and peaceful offering at the Kumbh 2025.',
};

export const whoWeArePage: Page = {
  title: 'camp67-navigation.camp67.who-are-we',
  subtitle: 'camp67-who-we-are.subtitle',
  path: PageRoutes.whoWeAre,
  heroImage: images['who-are-we-hero-v2'],
  heroImageAlt: 'camp67-who-we-are.hero-image-alt',
  readingTime: 2,
  summary: <FormattedString message="camp67-who-we-are-summary" />,
  content: (
    <FormattedString
      message="camp67-who-we-are.content"
      components={{
        paragraph: <p className="mt-5" />,
        'the-land-link': (
          <Link
            prefetch="intent"
            className="underline text-blue-500"
            to="/the-camp/camp67-and-the-land"
          />
        ),
        'camp67-kumbh-link': (
          <Link
            prefetch="intent"
            className="underline text-blue-500"
            to="/the-camp/camp67-and-kumbh"
          />
        ),
      }}
    />
  ),
  linkedPages: (s) => [
    s.getPageOrThrow(PageRoutes.camp67AndKumbh),
    s.getPageOrThrow(PageRoutes.camp67AndTheLand),
  ],
};
