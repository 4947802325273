import { images } from '../constants/images';
import { PageRoutes } from './page-routes';
import { Page } from './pages-types';
import { FormattedString } from '../i18n/useMessages';
import { Kumbh2025PageContent } from './kumbh-2025.content';

export const paushPurnimaMessages = {
  'paush-purnima.subtitle': 'The day of Lord Chandra.',
  'paush-purnima.hero-image-alt': 'A nighttime view of the full moon shrouded in clouds.',
  'paush-purnima.summary': 'A day which commemorates the Moon God, Lord Chandra.',
  'paush-purnima.content': `
    <p>
      Paush Purnima marks the full moon day in the month of Paush according to the Hindu
      lunar calendar. It presents a significant association with the Moon and is hence
      considered auspicious for taking a holy dip for purification, renewal, and overall
      cleansing of one’s physical and inner self in the Ganges.
    </p>
    <paragraph>
      It is also the most ideal day for one to seek blessings from the Hindu God of the
      Moon, Lord Chandra. Devotees observe a specific fast during on this day.
    </paragraph>
  `,
};

export const paushPurnimaPage: Page = {
  title: 'camp67-navigation.kumbh-2025.paush-purnima',
  subtitle: 'paush-purnima.subtitle',
  summary: <FormattedString message="paush-purnima.summary" />,
  path: PageRoutes.paushPurnima,
  heroImage: images['paush-purnima-hero'],
  heroImageAlt: 'paush-purnima.hero-image-alt',
  readingTime: 2,
  content: (
    <Kumbh2025PageContent contentKey="paush-purnima.content" eventId="paush-purnima" />
  ),
  linkedPages: (s) => [
    s.getPageOrThrow(PageRoutes.significanceOfKumbh),
    s.getPageOrThrow(PageRoutes.makarSankranti),
  ],
};
