import { images } from '../constants/images';
import { PageRoutes } from './page-routes';
import { Page } from './pages-types';
import { FormattedString } from '../i18n/useMessages';
import { Kumbh2025PageContent } from './kumbh-2025.content';

export const mahaShivratriMessages = {
  'maha-shivratri.subtitle': 'The Great Night of Shiva.',
  'maha-shivratri.hero-image-alt': 'A statue of Lord Shiva against a blue sky.',
  'maha-shivratri.summary':
    'A day dedicated to Lord Shiva, observed just before the conclusion of the Kumbh Mela.',
  'maha-shivratri.content': `
    <p>
      This is the day dedicated to Lord Shiva, observed just before the conclusion of
      the Kumbh Mela. Devotees fast and perform sacred rituals in honour of Lord Shiva.
      Bathing on this day is believed to honour Lord Shiva, helping devotees attain
      liberation and blessings from the Lord, and dispel all darkness from their lives.
    </p>
    <paragraph>
      Specific mantras and odes to the Lord are chanted and sung on this day.
    </paragraph>
  `,
};

export const mahaShivratriPage: Page = {
  title: 'camp67-navigation.kumbh-2025.maha-shivratri',
  subtitle: 'maha-shivratri.subtitle',
  summary: <FormattedString message="maha-shivratri.summary" />,
  path: PageRoutes.mahaShivratri,
  heroImage: images['maha-shivratri-hero'],
  heroImageAlt: 'maha-shivratri.hero-image-alt',
  readingTime: 2,
  content: (
    <Kumbh2025PageContent contentKey="maha-shivratri.content" eventId="maha-shivratri" />
  ),
  linkedPages: (s) => [
    s.getPageOrThrow(PageRoutes.significanceOfKumbh),
    s.getPageOrThrow(PageRoutes.paushPurnima),
  ],
};
