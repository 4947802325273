import { images } from '../constants/images';
import { PageRoutes } from './page-routes';
import { Page } from './pages-types';
import { FormattedString } from '../i18n/useMessages';

export const camp67AndTheLandMessages = {
  'camp67-and-the-land.subtitle': 'Ancient stories of India.',
  'camp67-and-the-land.hero-image-alt':
    'A sunset view of a port in Varanasi, with boats on the River Ganges.',
  'camp67-and-the-land.summary':
    'The land upon which camp67 sits is shrouded in a history which is no longer as apparent. It is one of the most unique locations in the world.',
  'camp67-and-the-land.content': `
    <paragraph>
      camp67 sits directly by the Sanskriti Vann. Translated literally, Sanskriti Vann is
      the ‘sanctified forest’. Thousands of years ago, this was a sacred location where
      Rishis and Maharishis would sit to meditate without interruption. If you’re
      unaware, this could vary from months to years to decades. Such is the uniqueness
      of the Rishis of India. There are also ties between the land which camp67 directly
      sits upon and the legends of Indra—the Hindu God of War, Rain and Thunder; the
      King of the Devās.
    </paragraph>
    <paragraph>
      Many of the Rishis who would meditate here would sit under the trees still present
      in the Sanskriti Vann. One notices this upon entering this space—the trees loom
      over you, but also offer an ancient protection from the hustle and bustle of the
      outside world.
    </paragraph>
    <paragraph>
      Imagine it for yourself: You leave the busy, noisy streets of India, and turn left
      into a campsite. Upon entering it, you can barely hear the outside world, and are
      nestled by an enclosure rich in history, surrounded by Nature’s Guard. It offers
      you a serenity which you’ve never experienced before anywhere else. During the
      Kumbh 2025, camp67ers will gain exclusive access to this unique forest.
    </paragraph>
    <paragraph>
      Lastly: consider the fact that just ten minutes from camp67 are a hundred million
      people, waiting to embark on their Kumbh journey. Suddenly, the allure of the land
      grows on you, and hence… you end up building a Kumbh campsite and invite everyone
      to join it!
    </paragraph>
    <paragraph>
      This is roughly (and truthfully) the way camp67 started. It was the land which
      came first.
    </paragraph>
  `,
};

export const camp67AndTheLandPage: Page = {
  title: 'camp67-navigation.camp67.camp67-&-the-land',
  subtitle: 'camp67-and-the-land.subtitle',
  path: PageRoutes.camp67AndTheLand,
  heroImage: images['camp67-and-the-land-hero'],
  heroImageAlt: 'camp67-and-the-land.hero-image-alt',
  readingTime: 2,
  summary: <FormattedString message="camp67-and-the-land.summary" />,
  content: (
    <FormattedString
      message="camp67-and-the-land.content"
      components={{
        paragraph: <p className="mt-5" />,
      }}
    />
  ),
  linkedPages: (s) => [
    s.getPageOrThrow(PageRoutes.significanceOfKumbh),
    s.getPageOrThrow(PageRoutes.whoWeAre),
  ],
};
