import { Link } from '@remix-run/react';
import { ChildPage } from './page-packages';
import { FormattedString } from '../i18n/useMessages';

export function ChildPagePreview({ path, readingTime, title, summary }: ChildPage) {
  return (
    <article className="h-full">
      <Link prefetch="intent" to={path}>
        <button className="px-9 py-6 w-full h-full bg-white rounded-lg shadow-md-blur shadow-black/20">
          <h1 className="font-serif text-blue-200 text-xl"><FormattedString message={title} /> &rarr;</h1>
          {readingTime ? (
            <span className="mt-2 text-xs text-gray-500 uppercase tracking-wider">
              {readingTime} <FormattedString message='camp67-content-page.child.reading-time' />
            </span>
          ) : null}
          <section className="mt-5 text-gray-500 text-ellipsis line-clamp-2">
            {summary}
          </section>
        </button>
      </Link>
    </article>
  );
}
