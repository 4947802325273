import { images } from '../constants/images';
import {
  camp67AndKumbhMessages,
  camp67AndKumbhPage,
} from './page-the-camp.camp67-and-kumbh';
import {
  camp67AndTheLandMessages,
  camp67AndTheLandPage,
} from './page-the-camp.camp67-and-the-land';
import { whoWeAreMessages, whoWeArePage } from './page-the-camp.who-are-we';
import { Page } from './pages-types';
import { ChildPagePreview } from './child-page-preview';
import { PageRoutes } from './page-routes';
import { FormattedString } from '../i18n/useMessages';

export const theCampPageChildren = [
  whoWeArePage,
  camp67AndKumbhPage,
  camp67AndTheLandPage,
];

export const theCampMessages = {
  ...whoWeAreMessages,
  ...camp67AndKumbhMessages,
  ...camp67AndTheLandMessages,
  'camp67-the-camp.subtitle': 'Learn more about us!',
  'camp67-the-camp.hero-image-alt':
    'A sunset view of a port in Varanasi, with boats on the River Ganges.',
  'camp67-the-camp.summary':
    'Learn more about why camp67 exists, and what drives us to do what we do.',
};

export const theCampPage: Page = {
  title: 'camp67-navigation.camp67',
  subtitle: 'camp67-the-camp.subtitle',
  summary: <FormattedString message="camp67-the-camp.summary" />,
  path: PageRoutes.theCamp,
  heroImage: images['who-are-we-hero-v2'],
  heroImageAlt: 'camp67-the-camp.hero-image-alt',
  readingTime: theCampPageChildren.reduce((acc, { readingTime }) => acc + readingTime, 0),
  maxWidth: true,
  content: (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
      {theCampPageChildren.map((props, index) => (
        <ChildPagePreview key={index} {...props} />
      ))}
    </div>
  ),
};
