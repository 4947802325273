import { images } from '../constants/images';
import { PageRoutes } from './page-routes';
import { Page } from './pages-types';
import { FormattedString } from '../i18n/useMessages';

export const significanceOfKumbhMessages = {
  'significance-of-kumbh.subtitle': 'The story of Kumbh.',
  'significance-of-kumbh.hero-image-alt':
    'The asuras (left) and devas (right) churn the Ocean of Milk.',
  'significance-of-kumbh.summary':
    'The story of Kumbh is both symbolic and literal. In a literal sense: Kumbh means ‘pot’. It represents a pot which contains the divine nectar of immortality.',
  'significance-of-kumbh.content': `
    <p>
      We celebrate Kumbh as a recognition of the eon-old battle between the Hindu
      pantheon of Gods (Devās) and demons (Rākshasas).
    </p>
    <paragraph>
      The story goes that many hundreds of years ago, the Hindu Gods and demons joined
      forces to churn the ocean of milk — Kshirasagara - to yield a most-prized
      possession; the nectar of immortality (Amritā). This was done by using Mount
      Mandar as the churning point, and the Serpent Vasuki as the rope. Lord Vishnu
      supported the mountain by taking on the form of a turtle.
    </paragraph>
    <paragraph>
      As they churned the ocean, divinity resulted: from it manifested Goddess Lakshmi
      (the Hindu Goddess of Wealth, Fortune, Fertility, Power, Beauty and Prosperity),
      the Moon, and the divine Amritā cladden in a pot (Kumbh) as expected. But… as soon
      as the nectar emerged, the demons tried to seize it for themselves. Tricking the
      demons by taking on the form of the Hindu Goddess of Enchantment, Mohini, Lord
      Vishnu distributed the Amritā amongst all of the Hindu gods.
    </paragraph>
    <paragraph>
      Running from the demons, as the Hindu Gods were chased around India and drank this
      nectar, droplets of the nectar of immortality fell upon different regions of
      India. These are the four locations in which Kumbh is celebrated, namely:
      Haridwar, Prayag, Nashik, and Ujjain. Hence goes the story of the Samudra Manthan,
      underlying ‘Kumbh’.
    </paragraph>
    <paragraph>
      In a more symbolic sense, Kumbh represents the human body, within which sits the
      nectar of immortality. Only the ancient Rishis and sages have ever spoken to this.
    </paragraph>
  `,
};

export const signifanceOfKumbhPage: Page = {
  title: 'camp67-navigation.kumbh-2025.significance-of-kumbh',
  subtitle: 'significance-of-kumbh.subtitle',
  path: PageRoutes.significanceOfKumbh,
  heroImage: images['significance-of-kumbh-hero'],
  heroImageAlt: 'significance-of-kumbh.hero-image-alt',
  readingTime: 3,
  summary: <FormattedString message="significance-of-kumbh.summary" />,
  content: (
    <FormattedString
      message="significance-of-kumbh.content"
      components={{
        paragraph: <p className="mt-5" />,
      }}
    />
  ),
  linkedPages: (s) => [
    s.getPageOrThrow(PageRoutes.camp67AndKumbh),
    s.getPageOrThrow(PageRoutes.mauniAmavasya),
  ],
};
