import { images } from '../constants/images';
import { PageRoutes } from './page-routes';
import { Page } from './pages-types';
import { FormattedString } from '../i18n/useMessages';
import { Kumbh2025PageContent } from './kumbh-2025.content';

export const maghuPurnimaMessages = {
  'maghi-purnima.subtitle': 'Day of the full moon.',
  'maghi-purnima.hero-image-alt':
    'An elderly sadhu dressed in orange in a darkened silhouette, against a blue sky.',
  'maghi-purnima.summary':
    'Associated with the Hindu god of creation, Brahma, and the community of saints who live by the river during the entire Kumbh period.',
  'maghi-purnima.content': `
    <p>
      This full moon day is associated with the Hindu god of creation, Brahma.
    </p>
    <paragraph>
      Taking a bath on this day is believed to bring blessings and support from all
      deities, and it is also associated with the community of saints or Kalpvasis, who
      live by the river during the entire Kumbh period, having renounced all other
      comforts.
    </paragraph>
  `,
};

export const maghuPurnimaPage: Page = {
  title: 'camp67-navigation.kumbh-2025.maghi-purnima',
  subtitle: 'maghi-purnima.subtitle',
  summary: <FormattedString message="maghi-purnima.summary" />,
  path: PageRoutes.maghiPurnima,
  heroImage: images['maghu-purnima-hero'],
  heroImageAlt: 'maghi-purnima.hero-image-alt',
  readingTime: 2,
  content: (
    <Kumbh2025PageContent contentKey="maghi-purnima.content" eventId="maghi-purnima" />
  ),
  linkedPages: (s) => [
    s.getPageOrThrow(PageRoutes.mahaShivratri),
    s.getPageOrThrow(PageRoutes.camp67AndKumbh),
  ],
};
