import { images } from '../constants/images';
import { Page } from './pages-types';
import { ChildPagePreview } from './child-page-preview';
import {
  signifanceOfKumbhPage,
  significanceOfKumbhMessages,
} from './page-kumbh-2025.significance-of-kumbh';
import { paushPurnimaMessages, paushPurnimaPage } from './page-kumbh-2025.paush-purnima';
import {
  makarSankrantiMessages,
  makarSankrantiPage,
} from './page-kumbh-2025.makar-sankranti';
import {
  mauniAmavasyaMessages,
  mauniAmavasyaPage,
} from './page-kumbh-2025.mauni-amavasya';
import {
  basantPanchamiMessages,
  basantPanchamiPage,
} from './page-kumbh-2025.basant-panchami';
import { achalSaptamiMessages, achalSaptamiPage } from './page-kumbh-2025.achal-saptami';
import {
  mahaShivratriMessages,
  mahaShivratriPage,
} from './page-kumbh-2025.maha-shivratri';
import { maghuPurnimaMessages, maghuPurnimaPage } from './page-kumbh-2025.maghi-purnima';
import { PageRoutes } from './page-routes';
import { FormattedString } from '../i18n/useMessages';

export const kumbh2025Messages = {
  ...significanceOfKumbhMessages,
  ...paushPurnimaMessages,
  ...mauniAmavasyaMessages,
  ...makarSankrantiMessages,
  ...mahaShivratriMessages,
  ...maghuPurnimaMessages,
  ...basantPanchamiMessages,
  ...achalSaptamiMessages,
  'kumbh-2025.subtitle': 'Embark upon your Kumbh 2025 journey.',
  'kumbh-2025.hero-image-alt':
    'A sunset view of a port in Varanasi, with boats on the River Ganges.',
  'kumbh-2025.summary':
    'Learn more about the significance of Kumbh Mela, and the events that take place during this grand festival.',
};

export const kumbh2025PageChildren = [
  signifanceOfKumbhPage,
  paushPurnimaPage,
  makarSankrantiPage,
  mauniAmavasyaPage,
  basantPanchamiPage,
  achalSaptamiPage,
  maghuPurnimaPage,
  mahaShivratriPage,
];

export const kumbh2025Page: Page = {
  title: 'camp67-navigation.kumbh-2025',
  subtitle: 'kumbh-2025.subtitle',
  summary: <FormattedString message="kumbh-2025.summary" />,
  path: PageRoutes.kumbh2025,
  heroImage: images['who-are-we-hero-v2'],
  heroImageAlt: 'kumbh-2025.hero-image-alt',
  readingTime: kumbh2025PageChildren.reduce(
    (acc, { readingTime }) => acc + readingTime,
    0,
  ),
  maxWidth: true,
  content: (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
      {kumbh2025PageChildren.map((props, index) => (
        <ChildPagePreview key={index} {...props} />
      ))}
    </div>
  ),
};
