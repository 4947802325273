import { images } from '../constants/images';
import { PageRoutes } from './page-routes';
import { Page } from './pages-types';
import { FormattedString } from '../i18n/useMessages';
import { Kumbh2025PageContent } from './kumbh-2025.content';

export const mauniAmavasyaMessages = {
  'mauni-amavasya.subtitle': 'The glory of Kumbh.',
  'mauni-amavasya.hero-image-alt':
    'A lightly sunlit view of individuals riding a boat on the vast River Ganges.',
  'mauni-amavasya.summary':
    'The primary bathing date of the Kumbh Mela; a day of silence & reflection, amongst millions.',
  'mauni-amavasya.content': `
    <p>
      Occurring on the new moon day, this date is considered the most auspicious day for
      bathing during the Kumbh Mela. Devotees observe Maun Vrat (the vow of silence,
      wherein one does not speak at all [the Rishis of India have further described it
      to be complete silence in one’s inner world]) on this day, reflecting in silence
      upon their spirituality, and performing rituals believed to grant them deep, inner
      benefits.
    </p>
    <paragraph>
      The phrase ‘Mauni’ is literally translated as ‘silence’. It is considered an
      essential part of the journey towards spiritual upliftment, which requires inner
      peacefulness.
    </paragraph>
  `,
};

export const mauniAmavasyaPage: Page = {
  title: 'camp67-navigation.kumbh-2025.mauni-amavasya',
  subtitle: 'mauni-amavasya.subtitle',
  summary: <FormattedString message="mauni-amavasya.summary" />,
  path: PageRoutes.mauniAmavasya,
  heroImage: images['mauni-amavasya-hero'],
  heroImageAlt: 'mauni-amavasya.hero-image-alt',
  readingTime: 2,
  content: (
    <Kumbh2025PageContent contentKey="mauni-amavasya.content" eventId="mauni-amavasya" />
  ),
  linkedPages: (s) => [
    s.getPageOrThrow(PageRoutes.basantPanchami),
    s.getPageOrThrow(PageRoutes.achalSaptami),
  ],
};
