import { images } from '../constants/images';
import { PageRoutes } from './page-routes';
import { Page } from './pages-types';
import { FormattedString } from '../i18n/useMessages';
import { Kumbh2025PageContent } from './kumbh-2025.content';

export const basantPanchamiMessages = {
  'basant-panchami.subtitle': 'The beginning of Spring.',
  'basant-panchami.hero-image-alt':
    'The Hindu Goddess Saraswati - Goddess of knowledge and arts. Taken from a painting by Raja Ravi Varma.',
  'basant-panchami.summary':
    'Celebrated as the arrival of Spring, Basant Panchami is dedicated to Saraswati, the Hindu Goddess of Knowledge, and Arts.',
  'basant-panchami.content': `
    <p>
      Celebrated as the arrival of Spring, Basant Panchami is dedicated to Saraswati,
      the Hindu Goddess of Knowledge, and Arts. Devotees wear yellow and offer yellow
      flowers to Goddess Saraswati accordingly. Educational institutions all around
      India pay their deepest respects to the Goddess, organizing special prayers.
    </p>
    <paragraph>
      Taking a holy dip on this auspicious blesses one with knowledge and wisdom.
    </paragraph>
  `,
};

export const basantPanchamiPage: Page = {
  title: 'camp67-navigation.kumbh-2025.basant-panchami',
  subtitle: 'basant-panchami.subtitle',
  summary: <FormattedString message="basant-panchami.summary" />,
  path: PageRoutes.basantPanchami,
  heroImage: images['basant-panchami-hero'],
  heroImageAlt: 'basant-panchami.hero-image-alt',
  readingTime: 2,
  content: (
    <Kumbh2025PageContent
      contentKey="basant-panchami.content"
      eventId="basant-panchami"
    />
  ),
  linkedPages: (s) => [
    s.getPageOrThrow(PageRoutes.achalSaptami),
    s.getPageOrThrow(PageRoutes.maghiPurnima),
  ],
};
