import React from 'react';
import { getDateString } from '@camp67/model';
import { Link } from '@remix-run/react';
import { FormattedString } from '../i18n/useMessages';
import { MessageKey } from '../locales/en';

export const events = [
  { name: 'paush-purnima', date: '13 Jan 2025', endDate: '15 Jan 2025' },
  { name: 'makar-sankranti', date: '14 Jan 2025', endDate: '16 Jan 2025' },
  { name: 'mauni-amavasya', date: '29 Jan 2025', endDate: '31 Jan 2025' },
  { name: 'basant-panchami', date: '3 Feb 2025', endDate: '5 Feb 2025' },
  { name: 'achal-saptami', date: '4 Feb 2025', endDate: '6 Feb 2025' },
  { name: 'maghi-purnima', date: '12 Feb 2025', endDate: '14 Feb 2025' },
  { name: 'maha-shivratri', date: '24 Feb 2025', endDate: '26 Feb 2025' },
] as const;

interface Kumbh2025PageContentProps {
  contentKey: MessageKey;
  eventId: (typeof events)[number]['name'];
}

export const Kumbh2025PageContent: React.FC<Kumbh2025PageContentProps> = ({
  contentKey,
  eventId,
}) => {
  const event = events.find((e) => e.name === eventId);
  if (!event) {
    return null;
  }

  const { date, endDate } = event;

  const from = getDateString(date);
  const to = getDateString(endDate);

  return (
    <div className="flex flex-col w-full">
      <FormattedString
        message={contentKey}
        components={{
          paragraph: <p className="mt-5" />,
        }}
      />
      <Link to={`/packages/reservation?from=${from}&to=${to}`} prefetch="intent">
        <button className="px-4 py-3 mt-6 w-full md:w-[unset] bg-red-200 text-white rounded-md uppercase text-sm tracking-wider font-medium">
          <FormattedString message="camp67-banner.book-now" />
        </button>
      </Link>
    </div>
  );
};
