import { images } from '../constants/images';
import { PageRoutes } from './page-routes';
import { Page } from './pages-types';
import { FormattedString } from '../i18n/useMessages';
import { Kumbh2025PageContent } from './kumbh-2025.content';

export const achalSaptamiMessages = {
  'achal-saptami.subtitle': 'The birth of the Sun.',
  'achal-saptami.hero-image-alt':
    'Warm orange skies illuminated by the sun, in an incoming sunset',
  'achal-saptami.summary':
    'A deeply auspicious day which brings about positive emotions, health and joy to those who worship and perform rituals.',
  'achal-saptami.content': `
    <p>
      The date on which Lord Surya (the Hindu God of the Sun) is believed to have been
      born. A deeply auspicious day which brings about positive emotions, health and joy
      to those who worship and perform rituals (including fasting). Observed on the
      seventh day (Saptami) of the waxing moon phase (Shukla Paksha) in the month of
      Magha according to the Hindu lunar calendar (January or February in the Gregorian
      calendar).
    </p>
    <paragraph>
      Taking a holy dip in a river on the day of Achal Saptami represents a promise to
      change and purify oneself.
    </paragraph>
  `,
};

export const achalSaptamiPage: Page = {
  title: 'camp67-navigation.kumbh-2025.achal-saptami',
  subtitle: 'achal-saptami.subtitle',
  summary: <FormattedString message="achal-saptami.summary" />,
  path: PageRoutes.achalSaptami,
  heroImage: images['achal-saptami-hero'],
  heroImageAlt: 'achal-saptami.hero-image-alt',
  readingTime: 2,
  content: (
    <Kumbh2025PageContent contentKey="achal-saptami.content" eventId="achal-saptami" />
  ),
  linkedPages: (s) => [
    s.getPageOrThrow(PageRoutes.maghiPurnima),
    s.getPageOrThrow(PageRoutes.mahaShivratri),
  ],
};
