import { images } from '../constants/images';
import { PageRoutes } from './page-routes';
import { Page } from './pages-types';
import { FormattedString } from '../i18n/useMessages';

export const camp67AndKumbhMessages = {
  'camp67-and-kumbh.subtitle': 'Why do we value Kumbh?',
  'camp67-and-kumbh.hero-image-alt':
    'A sunset view of a port in Varanasi, with boats on the River Ganges.',
  'camp67-and-kumbh.summary': `Why Kumbh? Of all the festivals and events, why choose Kumbh? Well, great question – it's all in the hidden detail…`,
  'camp67-and-kumbh.content': `
    <paragraph>
      We fell in love with Kumbh many years ago. There are very few events with such
      sheer scale and uniqueness in the world; one could go as far as to say there's
      very few such events in human history.
    </paragraph>
    <paragraph>
      Why is that? Well, where else will you observe more than 140 million people
      converge at the same place, with their own, individual hopes and dreams? It's a
      truly mammoth event, but the beauty of it is less in the numbers, and more in what
      you see on the ground.
    </paragraph>
    <paragraph>
      For starters: When you go to Kumbh you'll be greeted with countless small to
      medium sized meeting grounds on the banks of the Ganges River (just a short walk
      from our location at camp67) amid the tent cities which the city of Prayag Raj
      sets up before the event starts, and everywhere in between. It completely changes
      up your morning walk; you begin your loiter through the Kumbh grounds with one
      impression, and end it with your expectations completely reworked and perspective
      entirely shifted.
    </paragraph>
    <paragraph>
      What does this tangibly look like? Spiritual leaders from all over India come
      together en masse. Naga Sadhus of varying kinds and backgrounds present themselves
      to the general public with the aid of a story. Families from rural villages hail
      here to seek blessings for a new beginning in life. The stories and experiences
      are countless.
    </paragraph>
    <paragraph>
      One thing's for sure: You'll definitely leave Kumbh with a story of your own.
    </paragraph>
  `,
};

export const camp67AndKumbhPage: Page = {
  title: 'camp67-navigation.camp67.camp67-&-kumbh',
  subtitle: 'camp67-and-kumbh.subtitle',
  path: PageRoutes.camp67AndKumbh,
  heroImage: images['camp67-and-kumbh-hero'],
  heroImageAlt: 'camp67-and-kumbh.hero-image-alt',
  readingTime: 2,
  summary: <FormattedString message="camp67-and-kumbh.summary" />,
  content: (
    <FormattedString
      message="camp67-and-kumbh.content"
      components={{
        paragraph: <p className="mt-5" />,
      }}
    />
  ),
  linkedPages: (s) => [
    s.getPageOrThrow(PageRoutes.whoWeAre),
    s.getPageOrThrow(PageRoutes.camp67AndTheLand),
  ],
};
